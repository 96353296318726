<template>
  <div class="app">
    <Navigation/>
    <router-view class="router-view" v-slot="{ Component }">
      <Transition name="page-slide">
         <component :is="Component" mode="out-in"/>
      </Transition>
    </router-view>
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
export default {
  name: "App",
  components: {
    Navigation,
  }
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

.app {
  min-height: 100vh;
  position: relative;
  background-color: #000000;
}
.container {
  padding: 0 20px;
  max-width: 1140px;
  margin: 0 auto;
}
.page-opacity-enter-active,
.page-opacity-leave-active {
  transition: 500ms ease all;
}
.page-opacity-enter-from,
.page-opacity-leave-to {
  opacity: 0.2;
}

.page-slide-enter-active,
.page-slide-leave-active {
  transition: 400ms ease all;
}
.page-slide-enter-from,
.page-slide-leave-to {
  opacity: 0;
  transform: translateY(60px);

}
</style>
