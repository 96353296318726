<template>
    <header :class="{ 'scrolled-nav': scrolledNav }">
        <nav>
            <div class="branding" >
                <router-link class="link" :to="{ name: 'Home' }"
                @click="HomeButtonClickHandler">
                        <img src="@/assets/elmtreeArchersLogo300.png" alt=""
                        :class="{ 'home-active': homeActive }">
                </router-link>
            </div>
            <ul v-show="!mobile" class="navigation">
                <li><router-link class="link" :to="{ name: 'Home' }">Home</router-link></li>
                <li><router-link class="link" :to="{ name: 'Background' }">Background</router-link></li>
                <li><router-link class="link glow" :to="{ name: 'Events' }">Events</router-link></li>
                <li><router-link class="link" :to="{ name: 'Contact' }">Contact</router-link></li>
                <li><a class="link" 
                    href="https://m.facebook.com/pages/Elmtree-Archers/110709645186195/?locale=en_GB" 
                    target="_blank">Facebook</a></li>
            </ul>

            <div class="icon">
                
                <i @click="toggleMobileNav" v-show="mobile" class="fa-solid fa-bars" :class="{ 'icon-active': mobileNav }"></i>
            </div>
            <transition name="mobile-nav">
                <ul v-show="mobileNav" class="dropdown-nav" >
                    <li><router-link class="link-mob" :to="{ name: 'Home' }" 
                        @click="InlineButtonClickHandler">Home</router-link></li>
                    <li><router-link class="link-mob" :to="{ name: 'Background' }" 
                        @click="InlineButtonClickHandler">Background</router-link></li>
                    <li><router-link class="link-mob glow" :to="{ name: 'Events' }" 
                        @click="InlineButtonClickHandler">Events</router-link></li>
                    <li><router-link class="link-mob" :to="{ name: 'Contact' }" 
                        @click="InlineButtonClickHandler">Contact</router-link></li>
                    <li><a class="link-mob" 
                    href="https://m.facebook.com/pages/Elmtree-Archers/110709645186195/?locale=en_GB" 
                    target="_blank">Facebook</a></li>
                </ul>
            </transition>
        </nav>
    </header>
</template>

<script>
export default {
    name: "navigation",
    data() {
        return {
            scrolledNav: null,
            mobile: null,
            mobileNav: null,
            windowWidth: null,
            homeActive: null,
        }
    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    methods: {
        HomeButtonClickHandler() {
            if (this.mobile)
            this.homeActive = true;
        },
        InlineButtonClickHandler() {
            this.mobileNav = false;
            this.homeActive = false;
        },
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav;
        },

        updateScroll() {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 50) {
                this.scrolledNav = true;
                return;
            }
            this.scrolledNav = false;

        },

        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 870) {
                this.mobile = true;
                return;
            }
            this.mobile = false;
            this.mobileNav = false;
        },
        wrapNavigate(navigate, event) {
            this.mobileNav = false;
            navigate(event);
        },
    }
};

</script>

<style lang="scss" scoped>
header {
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
    width: 100%;
    position: fixed;
    transition: .5s ease all;
    color: #fff;

    nav {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 12px 0;
        transition: 0.5s ease all;
        width: 90%;
        margin: 0 auto;

        @media(min-width: 1140px) {
            max-width: 1140px;
        }

        ul,.link,.link-mob {
            font-weight: 500;
            color: #fff;
            list-style: none;
            text-decoration: none;

            a {
                font-weight: 500;
                text-decoration: none;
            }
        }

        li {
            text-transform: uppercase;
            padding: 16px;
            margin-left: 16px;
        }

        .link {
            font-size: 20px;
            transition: 0.5s ease all;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;

            &:hover {
                color: #00afea;
                border-color: #00afea;
            }
        }

        .branding {
            display: flex;
            align-items: center;

            img {
                width: 100px;
                transition: 0.5s ease all;
            }

        }

        .home-active,
        .branding-active {
            transform: rotate(360deg);
        }

        .navigation {
            display: flex;
            align-items: center;
            flex: 1;
            //justify-content: flex-end;

            justify-content: space-around;
        }

        .icon {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            right: 24px;
            height: 100%;

            i {
                cursor: pointer;
                font-size: 24px;
                transition: 0.8s ease all;
            }
        }

        .icon-active {
            transform: rotate(180deg);
        }

        .dropdown-nav {
            display: flex;
            flex-direction: column;
            position: fixed;
            width: 100%;
            max-width: 250px;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.9);
            top: 0;
            left: 0;
            //padding: 15px 10px;

            li {
                margin-left: 10px;
                margin-top: 20px;
                
                .link-mob {
                    color: #fff;
                    font-size: 34px;
                }
            }
        }

        .mobile-nav-enter-active,
        .mobile-nav-leave-active {
            transition: 1s ease all;
        }

        .mobile-nav-enter-from,
        .mobile-nav-leave-to {
            transform: translateX(-250px);
        }

        .mobile-nav-enter-to {
            transform: translateX(0);
        }


    }
}

.scrolled-nav {
    background-color: #000;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

    nav {
        padding: 8px 0;

        .branding {
            img {
                width: 30px;
                //box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

            }
        }
    }
}
</style>