<template>
  <div class="event">
    <section class="hero">
      <div class="hero-text container">
        <h4>Elmtree Archers</h4>
        <hr />
        <h2>
          Events
        </h2>
        <hr />
        <div class="event-item">
          <h1 class="glow">The North Devon <br/> Two Day Challenge</h1>
              
<p>
Hosted jointly by Elmtree Archers and Umberleigh Field Archers with both
days at Barton Wood, Umberleigh EX37 9LX (Umberleigh’s usual ground).<br/>

7 th and 8 th June 2025; 10.00am prompt start<br/>

The courses will be half 3Ds and half 2D cut outs.<br/>
The rounds will be Woodsman on Saturday and Big Game on Sunday<br/>
£20 for seniors, £10 for all juniors<br/>
For this shoot only, all entries to: Colin Smithers 31 Fore Street, Langtree,
Torrington EX38 8NG.</p>
<p>
<a
  href="#"
  data-contact="Y29saW4uc21pdGhlcnNAYnRpbnRlcm5ldC5jb20="
  data-subj="RWxtdHJlZSBBcmNoZXJzIEVucXVpcnk="
  onfocus="this.href = 'mailto:' + atob(this.dataset.contact) + '?subject=' + atob(this.dataset.subj || '')"
  >
  Email Colin
</a>
</p>
        </div>
      </div>
    </section>
  </div>
</template>
  
<script>
// @ is an alias to /src

export default {
  name: "EventPage",
  components: {},
};
</script>
  
<style lang="scss" scoped>

a {
  //text-decoration:none;
  color: whitesmoke;
  font-weight: 400;
}

.hero {
  background-image: url("../assets/ElmLeaves.png");
  box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, .5);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  padding: 100px 0;
  height: 100vh;

  img {
    //object-fit: fill; //was cover
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;

      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }

    hr:nth-child(4) {
      height: 6px;
      background-color: #fff;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }

    .event-item {
      margin: 20px;
      padding: 20px;
      border: solid;

      h1 {
        font-size: 40px;
      }
      h3 {
        padding-top: 20px;
        font-size: 35px;
      }
      p {
        padding-top: 10px;
        font-size: 25px;
      }
    }
  }
}
</style>
  