import {createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/Home.vue";
import Background from "../views/Background.vue";
import Events from "../views/Events.vue";
import Contact from "../views/Contact.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomePage,
    },{
        path: "/background",
        name: "Background",
        component: Background,
    },{
        path: "/contact",
        name: "Contact",
        component: Contact,
    },{
        path: "/events",
        name: "Events",
        component: Events,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;