<template>
  <div class="about">
    <section class="hero">
      <div class="hero-text container">
        <h4>Elmtree Archers</h4>
        <hr />
        <h2>
          Background
        </h2>
        <hr />
        <div>
          <p>Founded in 1982, based in the Holsworthy area. Devon, in the south west of the UK.</p>
          <p>Named from the ancient Elm Tree that once stood in Stanhope Square, Holsworthy which
            is the place where all the charters and proclaimations were read to the townsfolk for
            hundreds of years</p>
          <p>A small but very successful Field Archery Club affiliated to the 
            <a href="http://www.nfas.net" target="_blank">National Field Archery Society</a> 
            (NFAS) which is the largest field archery society in the UK</p>
          <p>There are two main types of archery:</p>
<p>Target archery is set out on a flat field where a set number of arrows are shot at
a five coloured 122cm round target set at a known distance (usually 70m).</p>
<p>Field archery is shot mainly in uneven wooded terrain over a course of 36 or 40
targets which are of an animal design, either paper pictures of animals or more
recently three dimensional life size foam models of animals. The course of
targets is set out in the woodland, rather like a golf course is set out where you
follow the course around shooting each target in turn. Each target is set at an
unknown distance from 5m to 70m depending on the size of the target, which
can vary from a life sized crow or squirrel to a life sized bison or elk. The
targets can be set at any angle, uphill or downhill or sometimes over water
across a lake. Each target, paper or foam have higher and lower scoring areas
marked on them which we term a kill or wound area.</p>
<p>It is an attempt to simulate the original skill of real hunting with a bow and
arrow which has not been legal in the UK for over eighty years. All we try to
kill is a piece of paper or foam.</p>
          <p>Past and current members of Elmtree have been National, European and <b>World</b> Champions.</p>
          <p>We cater for all styles of bows; from longbows, to Olympic and Compound bows</p>
          <p>We have our own outdoor range open to members 365 days a year, where they can
            shoot targets from ten yards to eighty yards. Also during the winter months we hire a local
            village hall one evening a month (to keep our eye in !)</p>
          <p>We offer starter courses for new prospective members of six, one hour sessions
            for a cost of £40.00 where all equipment is supplied and instruction is given from experienced
            archers. We accept new archers from the age of eight, but all juniors under the age of 16 must
            be accompanied at all times by a parent or legal gaurdian. After completing a starter course you
            are invited to join Elmtree Archers for an annual membership fee of £50.00 plus an NFAS
            membership of £20 for insurance cover.</p>
          <p>Some members travel all over the UK competing in competitions and open shoots with good
            success.</p>
          <p>Elmtree hold open shoots where members from other clubs compete and we have a very good
            reputation of setting top quality courses.</p>
          <p>For further details <a
  href="#"
  data-contact="Y29saW4uc21pdGhlcnNAYnRpbnRlcm5ldC5jb20="
  data-subj="RWxtdHJlZSBBcmNoZXJzIEVucXVpcnk="
  onfocus="this.href = 'mailto:' + atob(this.dataset.contact) + '?subject=' + atob(this.dataset.subj || '')"
  >
  contact Colin
</a></p>
        </div>
      </div>
    </section>
  </div>
</template>
  
<script>
// @ is an alias to /src

export default {
  name: "BackGround",
  components: {},
};
</script>
  
<style lang="scss" scoped>

a {
  //text-decoration:none;
  color: whitesmoke;
  font-weight: 400;
}
.hero {
  background-image: url("../assets/ElmLeaves.png");
  box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, .5);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  padding: 140px 0;
  //height: 100vh;

  img {
    //object-fit: fill; //was cover
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    p {
      padding: 8px;
      font-size: 24px;
    }

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;

      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }

    hr:nth-child(4) {
      height: 6px;
      background-color: #fff;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }
  }
}
</style>
  