<template>
  <div class="contact">
    <section class="hero">
      <div class="hero-text container">
        <h4>Elmtree Archers</h4>
        <hr />
        <h2>
          Contact Info
        </h2>
        <hr />
        <div>
          <p>For further details, please <a
  href="#"
  data-contact="Y29saW4uc21pdGhlcnNAYnRpbnRlcm5ldC5jb20="
  data-subj="RWxtdHJlZSBBcmNoZXJzIEVucXVpcnk="
  onfocus="this.href = 'mailto:' + atob(this.dataset.contact) + '?subject=' + atob(this.dataset.subj || '')"
  >
  contact Colin
</a>.</p>
          </div>
      </div>

    </section>
  </div>
</template>
  
<script>
// @ is an alias to /src

export default {
  name: "ContactPage",
  components: {},
};
</script>
  
<style lang="scss" scoped>

a {
  //text-decoration:none;
  color: whitesmoke;
  font-weight: 400;
}

.hero {
  background-image: url("../assets/ElmLeaves.png");
  box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, .5);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  padding: 100px 0;
  height: 100vh;

  img {
    //object-fit: fill; //was cover
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    p {
      padding: 8px;
      font-size: 24px;
    }
    
    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;

      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }

    hr:nth-child(4) {
      height: 6px;
      background-color: #fff;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }
  }
}
</style>
  